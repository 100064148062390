body {
  font-family: Arial, Helvetica, sans-serif;
  /* font-family: Lucida; */
  font-size: 16.5px;
}

.navTop {
  position: absolute;
  top: 0px;
  opacity: 0.8;
}

/*================Service We Provide=====================*/

section {
  padding: 60px 0;
  min-height: 100vh;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab-pane>p>ul {
  margin: 0;
  padding-left: 30px;
  list-style: square;
}

.bg-gray {
  background-color: #f9f9f9;
}

.site-heading h2 {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}

.site-heading h2 span {
  color: #ff875b;
}

.site-heading h4 {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  font-size: 18px;
}

.site-heading h4::before {
  background: #ff875b none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

.site-heading {
  margin-bottom: 20px;
  overflow: hidden;
  margin-top: -5px;
}

.img-fluid-logo {
  width: 15%;
  height: auto;
}

.carousel-shadow .owl-stage-outer {
  margin: -15px -15px 0;
  padding: 15px;
}

.we-offer-area .our-offer-carousel .owl-dots .owl-dot span {
  background: #ffffff none repeat scroll 0 0;
  border: 2px solid;
  height: 15px;
  margin: 0 5px;
  width: 15px;
}

.we-offer-area .our-offer-carousel .owl-dots .owl-dot.active span {
  background: #ff875b none repeat scroll 0 0;
  border-color: #00a01d;
}

.we-offer-area .item {
  background: #ffffff none repeat scroll 0 0;
  border-left: 2px solid #00a01d;
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  overflow: hidden;
  padding: 30px;
  position: relative;
  z-index: 1;
}

.we-offer-area.text-center .item {
  background: #ffffff none repeat scroll 0 0;
  border: medium none;
  padding: 50px 20px;
  min-height: 250px;
}

.we-offer-area.text-center .item i {
  font-size: 68px;
  position: relative;
  text-align: center;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  margin-bottom: 25px;
}

.we-offer-area.text-center .item i::after {
  content: "";
  left: -5px;
  position: absolute;
  top: -5px;
  z-index: -1;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.we-offer-area.item-border-less .item {
  border: medium none;
}

.we-offer-area .our-offer-items.less-carousel .equal-height {
  margin-bottom: 30px;
}

.we-offer-area.item-border-less .item .number {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 900;
  opacity: 0.1;
  position: absolute;
  right: 30px;
  top: 30px;
}

.our-offer-carousel.center-active .owl-item:nth-child(2n) .item,
.we-offer-area.center-active .single-item:nth-child(2n) .item {
  background: #ff875b none repeat scroll 0 0;
}

.our-offer-carousel.center-active .owl-item:nth-child(2n) .item i,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item h4,
.our-offer-carousel.center-active .owl-item:nth-child(2n) .item p,
.we-offer-area.center-active .single-item:nth-child(2n) .item i,
.we-offer-area.center-active .single-item:nth-child(2n) .item h4,
.we-offer-area.center-active .single-item:nth-child(2n) .item p {
  color: #ffffff;
}

.we-offer-area .item i {
  color: #ff875b;
  display: inline-block;
  font-size: 60px;
  margin-bottom: 20px;
}

.we-offer-area .item h4 {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1.0rem;
}

.we-offer-area .item p {
  margin: 0;
  font-size: 0.9rem;
}

.we-offer-area .item i,
.we-offer-area .item h4,
.we-offer-area .item p {
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}

.we-offer-area .item::after {
  background: #064c6e none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  width: 100%;
  z-index: -1;
}

.we-offer-area .item:hover::after {
  left: 0;
}

.we-offer-area .item:hover i,
.we-offer-area .item:hover h4,
.we-offer-area .item:hover p {
  color: #ffffff !important;
}

.we-offer-area.text-center .item:hover i::after {
  border-color: #ffffff !important;
}

.we-offer-area.text-center .item:hover i {
  color: #ff875b !important;
}

.we-offer-area.text-left .item i {
  background: #ff875b none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  font-size: 60px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 30px;
  position: relative;
  width: 100px;
  z-index: 1;
  text-align: center;
}

.we-offer-area.text-left .item i::after {
  border: 2px solid #8f8784;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  content: "";
  height: 120px;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 120px;
}



/*=================================================*/
.card {
  border: none;
  border-radius: 0;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
}

.carousel-inner-two {
  padding: 1em;
}

@media screen and (min-width: 577px) {
  .cards-wrapper {
    display: flex;
    min-height: 425px;
  }

  .card {
    margin: 0 0.5em;
    /*width: calc(100% / 2);*/
  }

  .image-wrapper {
    margin: 0 auto;
  }
}

@media screen and (min-width: 577px) {

  .card2 {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .image-wrapper {
    margin: 0 auto;
  }
}

/* @media screen and (max-width: 576px) {
  .card:not(:first-child) {
    display: none;
  }
} */

.image-wrapper img {
  max-width: 100%;
  min-height: 210px;

}

/* gallery imageclass start  */


/* Image styling for consistent 600x420 size */
.image-wrapper2 img {
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 420px;
  object-fit: cover; 
}

/* Gallery grid for 3-column layout */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* 3 columns on desktop, fewer on smaller screens */
  gap: 15px; /* Space between items */
}
.gallery-grid img {
  width: 100%; /* Full width of the grid item */
  height: 300px; /* Fixed height for all images */
  object-fit: cover; /* Crop the image to fit without distortion */
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better aesthetics */
}
/* Card wrapper */
.cards-wrapper2 .card {
  width: 100%;
  max-width: 600px; /* Ensures max image width */
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

/* Hover effect */
.cards-wrapper2 .card:hover {
  transform: scale(1.05);
}

.custom-container2 {
  padding-top: 120px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}
.custom-containergallery {
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}
/* gallery imageclass end  */

.hotPackTour {
  background: #f2f2f2;
}

.btn-Warning {
  background: #ff875b;
  color: #fff;
  font-size: 16px;
}

.card-title {
  font-size: 17px;
  color: #000;
  line-height: 30px;
  font-weight: bold !important;
}

.card-title span {
  font-size: 14px;
  color: orangered;
  float: right;
  line-height: 30px;
  font-weight: bold;
}


.counters {
  background: #fff;
  color: #fff;
  padding: 40px 20px;
}

.counters .container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  text-align: center;
}

.counters i {
  color: lightskyblue;
  margin-bottom: 5px;
}

.counters .counter {
  font-size: 35px;
  margin: 10px 0;
  color: #fff;
}

@media (max-width: 700px) {
  .counters .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .counters .container>div:nth-of-type(1),
  .counters .container>div:nth-of-type(2) {
    border-bottom: 1px lightskyblue solid;
    padding-bottom: 20px;
  }
}

.bgImgCounter {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/beautiful-3.jpg);
}

.onlyCounterBG {
  background: #0c5d99;
  opacity: 0.8;
  color: #000 !important;
  padding: 60px 10px;
  border-radius: 10px;
  margin-top: 10%;
}

.onlyCounterBG h3 {
  color: #fff;
  font-size: 18px;
}

/*======================Testimonials===============*/

/*================================*/
.section-parallax {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  color: #000000;
  padding: 5em 0;
  background-image: linear-gradient(to left top, #dedede, #dedede, #dedede, #dedede, #dedede);
  min-height: 10vh;
}

.subscribe-form .form-group {
  position: relative;
  margin-bottom: 0;
  border: 1px solid rgba(255, 255, 255, 0.7);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.subscribe-form .form-group .submit {
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  color: #fff !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  font-size: 16px;
}

.subscribe-form .form-group input {
  background: transparent !important;
  border: 1px solid transparent;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 1px;
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 16px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  color: #fff !important;
}


/*----------------------Footer-----------------*/

.footer-section {
  background: #151414;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}

.footer-widget ul li {
  display: inline-block;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #ff5e14;
}

.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}

.address-details-list ul li{
  width: 100%!important;
  margin-bottom: 10px;
}

.copyright-area {
  /* background: #202020;*/
  padding: 25px 0;
  color: #fff;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a {
  color: #ff5e14;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.bg-bottom {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: bottom center;
  background: url(../images/Website-Footer-1.png);
}
.bg-bottom-mobile {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: bottom center;
  background: url(../images/Website-Footer.png);
}
/*===========================*/

.heading-section-white h2 {
  font-size: 3rem;
}

.card-text {
  font-size: 13px;
  color: #000;
}



.nav-link {
  padding: 0rem 1.2rem !important;
  color: #fff !important;
}

.navbar {
  padding: 0.1rem 1rem;
}

.navbar .megamenu {
  padding: 1rem;
}

.borderRgt {
  border-right: 1px dashed #ccc;
}

.borderRadius5PX {
  border-radius: 5px;
}

.dropdown-menu h5 {
  font-size: 14px;
  font-weight: bold;
  padding: 0.1rem 1rem;
  margin-bottom: 0px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.1rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 14px;
  margin-top: 2px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #d2f0ff;
  border-radius: 5px;
  border: 1px dashed #ccc;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #78d5ef;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.navbar-dark {
  background-color: #005595 !important;
  opacity: 1.0;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .navbar .has-megamenu {
    position: static !important;
  }


  .navbar .has-megamenu:hover .megamenu {
    display: block;
}

/* .megamenu {
    display: none;
    position: absolute; 
    top: 100%; 
    left: 0;
    width: 100%; 
    background-color: #fff; 
    z-index: 1000; 
} */
  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
  .megamenu {
    display: block; /* Make it block so opacity and visibility can be used */
    opacity: 0;
    visibility: hidden; /* Hidden by default */
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0s 0.3s; /* Delay visibility change */
}

.megamenu.show {
    opacity: 1;
    visibility: visible; /* Show when class 'show' is applied */
    transition-delay: 0s; /* No delay for showing */
}
}

/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media(max-width: 991px) {

  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}

/* ============ mobile view .end// ============ */



.btn-custom {
  background: none;
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
  display: block;
  padding: 3px 15px;
  height: 30px;
}

.btn-custom:hover {
  background: #008acd;
  color: #fff;
  display: block;
  padding: 0px 15px;
}

.modal-header {
  background: #005595;
  color: #fff;
}
.modal {
  background:rgba(31,31,31,0.9);
  /* backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); */
  }
.cust-modContent .form-control,
.form-select {
  border: 1px solid #b7c2cd !important;
}

.cust-modContent .btn-close {
  color: #fff;
}






/*=========================Package========================*/

/*.sidebar-item {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
  }*/


.make-me-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10%;

  padding: 0 15px;
}

.rightFixedForm {
  background: #e7f1ff;
  border: 1px solid #ccc;
  color: #000;
  padding: 20px 10px;
  min-height: auto;
  border-radius: 5px;
}

.borderRadius5PX {
  border-radius: 5px;
}

.accordion-title:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f068";
  padding-right: 5px;
}

.accordion-title.collapsed:before {
  float: right !important;
  content: "\f067";
}

.icon-packDetaild {
  font-size: 28px;
  color: #999;
  float: left;
  position: relative;
  top: 0px;
}

.content-packDetaild {
  margin-top: 0px;
  margin-left: 40px;
}

/*====================FAQ====================*/

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  background-color: #ff875b;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 10px 40px;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}

.faq .faq-list i {
  font-size: 16px;
  position: absolute;
  left: -25px;
  top: 6px;
  transition: 1s;
}

.faq-title {
  text-align: center;
  font-size: 15px;
  border-bottom: 2px dashed #ffffff;
  margin-bottom: 30px;
  padding-bottom: 10px;
  color: #ffffff;
}

.faq .faq-list p {
  padding-top: 5px;
  margin-bottom: 20px;
  font-size: 14px;
}


.collapsed i.fa.fa-arrow-up {
  transform: rotate(180deg);
}

.placeToVisit {
  font-size: 16px;
}

li.featursPresent {
  display: block;
  float: left;
  list-style-type: none;
  margin-right: 20px;
  color: orangered;
}

.card-body ul {
  margin: 0px;
  padding: 0px;
}

.faQStyle .accordion-button {
  background: #005595;
  color: #fff;
  margin: 2px 0px;
  border-radius: 5px !important;
}

.custHeaer {
  background: #005595;
  padding: 15px;
  color: #fff;
}

.sticky-top {
  top: 75px
}

.rightFixedForm .form-control {
  margin: 15px 0px;
}

.list-inline-block li {
  display: inline-block;
  padding: 10px 10px 0px 0px;
  width: auto;
  font-size: 14px;
}

.packageListing li {
  display: block;
  padding: 10px 10px 0px 0px;
  font-size: 14px;
}

.packageListing h2 {
  font-size: 16px;
  font-weight: bold;
}

.packageListing li::before {
  content: "\f006";
  font-family: FontAwesome;
  display: block;
  width: 20px;
  height: 20px;
  float: left;
  color: orangered;
  margin: 0 6px 0 0;
}

.placetoVisit li {
  display: block;
  padding: 20px 10px 0px 0px;
  font-size: 14px;
}

.placetoVisit h2 {
  font-size: 16px;
  font-weight: bold;
}

.placetoVisit li::before {
  content: "\f058";
  font-family: FontAwesome;
  font-size: 18px;
  line-height: 24px;
  display: block;
  width: 20px;
  height: 20px;
  float: left;
  color: #005595;
  margin: 0px 6px 0 0;
}

.placetoVisit strong {
  color: orangered;
}



#testimonials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.testimonial-heading {
  letter-spacing: 1px;
  margin: 30px 0px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonial-heading span {
  font-size: 1.3rem;
  color: #252525;
  margin-bottom: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.testimonial-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.testimonial-box {
  width: auto;
  background-color: #ffffff;
  padding: 20px;
  margin: 5px;
  cursor: pointer;
  border-bottom: 1px dashed #ddd;
}

.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.profile {
  display: flex;
  align-items: center;
}

.name-user {
  display: flex;
  flex-direction: column;
}

.name-user strong {
  color: #3d3d3d;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
}

.name-user span {
  color: #979797;
  font-size: 0.8rem;
}

.reviews {
  color: orangered;
}

.box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.client-comment p {
  font-size: 0.9rem;
  color: #4b4b4b;
}

.testimonial-box:hover {
  transform: translateY(-10px);
  transition: all ease 0.3s;
}

@media(max-width:1060px) {
  .testimonial-box {
    width: 45%;
    padding: 10px;
  }
}

@media(max-width:790px) {
  .testimonial-box {
    width: 100%;
  }

  .testimonial-heading h1 {
    font-size: 1.4rem;
  }
}

@media(max-width:340px) {
  .box-top {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .reviews {
    margin-top: 10px;
  }
}

::selection {
  color: #ffffff;
  background-color: #252525;
}


.rate {
  float: right;
  height: 35px;
  padding: 0 10px;
}

.rate:not(:checked)>input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked)>label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 20px;
  color: #ccc;
  margin-left: 10px;
}

.rate:not(:checked)>label:before {
  content: '★ ';
}

.rate>input:checked~label {
  color: #ffc700;
}

.rate:not(:checked)>label:hover,
.rate:not(:checked)>label:hover~label {
  color: #deb217;
}

.rate>input:checked+label:hover,
.rate>input:checked+label:hover~label,
.rate>input:checked~label:hover,
.rate>input:checked~label:hover~label,
.rate>label:hover~input:checked~label {
  color: #c59b08;
}

.line-H-35 {
  line-height: 35px;
}

.accordion-button {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: bold;
  color: #0c63e4;
}

.accordion-button:not(.collapsed) {
  color: orangered;
}

.accordion-item button::before {
  /* content: "\f058"; */
  font-family: FontAwesome;
  font-size: 18px;
  line-height: 22px;
  display: block;
  width: 20px;
  height: 20px;
  float: left;
  color: #005595;
  margin: 0px 6px 0 0;
}


.box-bottom {
  padding: 10px;
  line-height: 18px;
  border: 1px solid #ddd;
  border-top: none;
  font-size: 12px;
  text-align: center;
  background-color: #f9f9f9;
}

.box-bottom h6 {
  font-size: 14px;
}

.full-width {
  width: 100%;
}

.table thead {
  background-color: #f7a78a;
  color: #000
}

.table td,
.table th {
  padding: 8px;
}


/* -------------------------------Custom Css Start-------------------------------*/

.tomato {
  color: #ff875b;
}

.dark-blue {
  color: #005595;
}

.black {
  color: black;
}

.icon-img-color {
  font-size: 1em;
  color: #ff875b;
}


/* .nav-tabs .nav-item .nav-link {
  background-color: #ff875b;
  color: #FFF;
}

.nav-tabs .nav-item .nav-link.active {
  color:#ff875b;
}

.tab-content {
  border: 1px solid #dee2e6;
  border-top: transparent;
  padding: 15px;
  border-radius:8px;
}

.tab-content .tab-pane {
  background-color: #FFF;
  color: black;
  min-height: 200px;
  height: auto;
  
} */

.tab {
  padding: 10px 20px; /* Adjust padding as needed for the standard size */
  background-color: #ff875b; /* Default background color */
  border: none;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition on hover */
}

.tab:hover {
  background-color: #005595;
   /* Change color on hover */
}

.tab.active {
  background-color: #ff875b; /* Change color for the active tab */
}
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #e7f1ff;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 15px;
  border-top-left-radius: 5px;
  /* Adjust the radius value as needed */
  border-bottom-left-radius: 5px;
  /* Adjust the radius value as needed */
  border-top-right-radius: 5px;
  /* Adjust the radius value as needed */
  border-bottom-right-radius: 5px;
  /* Adjust the radius value as needed */
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ccc;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ff875b;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-top: none;
}

.green-dot {
  color: green;
}

.red-dot {
  color: red;
}

.center {
  text-align: center;
}

.content-shadow {
  text-shadow: 0.025em 0.025em 0 black;
}

.table {
  border: 1px solid #ddd;
}

.fa-building.tomato {
  position: relative;
}

.fa-building.tomato::after {
  content: "Hotel 3 Star TCB Approved";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  /* Background color of the tooltip */
  color: white;
  /* Text color of the tooltip */
  padding: 5px 10px;
  /* Adjust the padding as needed */
  border-radius: 5px;
  /* Rounded corners for the tooltip */
  top: -30px;
  /* Position above the element */
  left: 50%;
  /* Center horizontally relative to the element */
  transform: translateX(-20%);
  z-index: 1;
  /* Ensure it's above the icon */
  white-space: nowrap;
  /* Prevent line breaks in the tooltip */
  opacity: 0;
  /* Initially hidden */
  display: flex;
  /* Use flexbox */
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  transition: opacity 0.3s ease;
}

.fa-building.tomato:hover::after {
  opacity: 1;
  /* Show on hover */
}

/* food hover */

.fa-utensils.tomato {
  position: relative;
}

.fa-utensils.tomato::after {
  content: "Food";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  /* Background color of the tooltip */
  color: white;
  /* Text color of the tooltip */
  padding: 5px 10px;
  /* Adjust the padding as needed */
  border-radius: 5px;
  /* Rounded corners for the tooltip */
  top: -30px;
  /* Position above the element */
  left: 50%;
  /* Center horizontally relative to the element */
  transform: translateX(-50%);
  z-index: 1;
  /* Ensure it's above the icon */
  white-space: nowrap;
  /* Prevent line breaks in the tooltip */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;
}

.fa-utensils.tomato:hover::after {
  opacity: 1;
  /* Show on hover */
}

/* Car hover */

.fa-car-side.tomato {
  position: relative;
}

.fa-car-side.tomato::after {
  content: "Vehicle";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  /* Background color of the tooltip */
  color: white;
  /* Text color of the tooltip */
  padding: 5px 10px;
  /* Adjust the padding as needed */
  border-radius: 5px;
  /* Rounded corners for the tooltip */
  top: -30px;
  /* Position above the element */
  left: 50%;
  /* Center horizontally relative to the element */
  transform: translateX(-50%);
  z-index: 1;
  /* Ensure it's above the icon */
  white-space: nowrap;
  /* Prevent line breaks in the tooltip */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;
}

.fa-car-side.tomato:hover::after {
  opacity: 1;
  /* Show on hover */
}

/* Guide hover */

.fa-male.tomato {
  position: relative;
}

.fa-male.tomato::after {
  content: "Guide";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  /* Background color of the tooltip */
  color: white;
  /* Text color of the tooltip */
  padding: 5px 10px;
  /* Adjust the padding as needed */
  border-radius: 5px;
  /* Rounded corners for the tooltip */
  top: -30px;
  /* Position above the element */
  left: 50%;
  /* Center horizontally relative to the element */
  transform: translateX(-50%);
  z-index: 1;
  /* Ensure it's above the icon */
  white-space: nowrap;
  /* Prevent line breaks in the tooltip */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;
}

.fa-male.tomato:hover::after {
  opacity: 1;
  /* Show on hover */
}


/* sightseeing hover */

.fa-camera.tomato {
  position: relative;
}

.fa-camera.tomato::after {
  content: "Sightseeing";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  /* Background color of the tooltip */
  color: white;
  /* Text color of the tooltip */
  padding: 5px 10px;
  /* Adjust the padding as needed */
  border-radius: 5px;
  /* Rounded corners for the tooltip */
  top: -30px;
  /* Position above the element */
  left: 50%;
  /* Center horizontally relative to the element */
  transform: translateX(-50%);
  z-index: 1;
  /* Ensure it's above the icon */
  white-space: nowrap;
  /* Prevent line breaks in the tooltip */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;
}

.fa-camera.tomato:hover::after {
  opacity: 1;
  /* Show on hover */
}

.font-weight {
  font-weight: 600;
}

.rating-box {
  display: inline-block;
  background-color: #ff875b;
  color: white;
  padding: 2px 4px;
  /* Adjust padding as needed */
  border-radius: 5px;
  /* Rounded corners for the box */
  font-size: 12px;
  /* Adjust the font size as needed */
}

.star {
  font-size: 15px;
  /* Adjust the font size for the star */
  vertical-align: middle;
  margin-right: 5px;
  /* Adjust spacing between star and value */
}

.rating-value {
  vertical-align: middle;
}

.clickable:hover span {
  color: #ff875b;
}

.kolkata-table {
  width: 70%;
  display: inline-table;
  margin-left: 15%;
}

.heading-kolkata {
  color: #005595;
}

/* Style the tab-flight */
.tab-flight {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #005595;
}

.tablinks1 {
  color: white;
  margin-right: 5px;
}

/* Style the buttons inside the tab */
.tab-flight button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 13px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: 0.3s;
  font-size: 15px;
  border-top-left-radius: 5px;
  /* Adjust the radius value as needed */
  border-bottom-left-radius: 5px;
  /* Adjust the radius value as needed */
  border-top-right-radius: 5px;
  /* Adjust the radius value as needed */
  border-bottom-right-radius: 5px;
  background-color: #ccc;
  /* Adjust the radius value as needed */
}

/* Change background color of buttons on hover */

/* Create an active/current tablink class */
.tab-flight button.active {
  background-color: #ff875b;
}

.tabcontent1 {
  display: none;
  padding: 8px 14px;
  border: 1px solid #ccc;
  border-top: none;
}


/* Style the tab-train */
.tab-train {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #005595;
}

.tablinks2 {
  color: white;
  margin-right: 5px;
}

/* Style the buttons inside the tab */
.tab-train button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 13px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: 0.3s;
  font-size: 15px;
  border-top-left-radius: 5px;
  /* Adjust the radius value as needed */
  border-bottom-left-radius: 5px;
  /* Adjust the radius value as needed */
  border-top-right-radius: 5px;
  /* Adjust the radius value as needed */
  border-bottom-right-radius: 5px;
  background-color: #ccc;
  /* Adjust the radius value as needed */
}


/* Create an active/current tablink class */
.tab-train button.active {
  background-color: #ff875b;
}

.tabcontent2 {
  display: none;
  padding: 8px 14px;
  border: 1px solid #ccc;
  border-top: none;
}

.white {
  color: white;
}

.overflow-table {
  overflow-x: auto;
}

.text-center-table {
  display: flex;
  align-items: center;
}

.place {
  list-style-type: circle;
}

.sdf-blue {
  color: red;
}
.info-red{
  color: #dc3545;
}
.flight-red {
  background-color: #dc3545;
  color: white; /* Text color for contrast */
  padding: 10px; /* Add padding for spacing */
  border-radius: 5px; /* Optional: rounded corners */
  display: inline-block; /* Ensure background wraps tightly around text */
}
.fa-instagram {
  background: #f40083;
}

/*======================05-11-23====================*/
.navtabCustom .nav-tabs a.nav-item {
  padding: 8px 24px !important;
  color: #000 !important;
}

.navtabCustom .tab-pane {
  padding: 25px 0px;
}

.navtabCustom .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 4px solid #008acd;
  border-top: none;
  border-left: none;
  border-right: none;
}

.navtabCustom .nav-tabs a:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  color: #008acd !important;
  padding: 0px;
}

.navtabCustom .nav-link {
  transition: none;
  border: 0px
}

.cust-card-wrap {
  min-height: 500px;
}

/* -------------------------------Custom Css End-------------------------------*/

/*================================18-11-2023===================================*/
.coreValue h2 {
  font-size: 28px;
  font-weight: bold;
  color: tomato;
  padding-top: 20px;
}

.coreValue span {
  font-size: 16px;
  font-weight: bold;
  color: #666;
  padding-top: 10px;
}

.coreValue li {
  font-size: 14px;
  font-weight: bold;
  color: #666;
  padding: 5px;
  list-style-type: disc;
  list-style-position: inside;
}

.imgStyle {
  border: 3px solid #0d6efd;
  border-radius: 0px 25px 0px 25px;
}

.team-area {
  padding-top: 5%;
}

.single-team {
  background-color: #666;
  margin-bottom: 10px;
}

.single-team:hover .social {
  cursor: pointer;
  opacity: 1;
  transform: rotateY(0deg) scale(1, 1);
}

.img-area {
  overflow: hidden;
  position: relative;
  border: 1px solid #666;
}

.img-area img {
  width: 100%;
}

.img-area:hover img {
  transform: scale(1.2)
}

.img-area img {
  transition: all 0.4s ease 0s;
}

@media (max-width: 768px) {
  .img-area img {
    display: inline-block;
  }
}

.img-area .social {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: .5s;
  transform: rotateY(180deg) scale(0.5, 0.5);
}

.img-area .social ul {
  text-align: center;
  position: relative;
  top: 45px;
}

.img-area .social ul li a {
  border: 1px solid #fff;
  color: #fff;
  display: block;
  font-size: 13px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
}

.img-area .social ul li a:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid transparent;
}

.img-text {
  padding: 25px 5px;
  color: #fff;
  text-align: center;
}

.img-text h4 {
  margin: 0 0 5px;
  font-size: 12px;
  letter-spacing: 0px;
}

.img-text h5 {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
}

/* ignore the code below */


.link-area {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 15px;
  border-radius: 40px;
  background: tomato;
}

.link-area a {
  text-decoration: none;
  color: #fff;
  font-size: 25px;
}

.imgCustWidth {
  width: 20%;
}

@media only screen and (max-width : 480px) {
  .imgCustWidth {
    width: auto;
  }
}

/* -------------------------------Custom Css End-------------------------------*/
/*=================01-12-2023=============================*/
.table-borderDodont td {
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 13px;
  vertical-align: text-top;
  width: 50%;
}

.table-borderDodont span {
  padding-right: 10px;
}

/*==============================13-12-2023==========================*/
.blackStrap{ background: #000; padding: 2px 5px; color: #fff;text-align: center; border-bottom: 1px dashed #999;}
.blackStrap ul li{ list-style-type: none; display:inline-block; font-size: 12px; text-align: center; padding: 5px 20px;}
.carousel-inner{box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);}
.centerAlignPh{ width: auto; margin: 0 auto; text-align: center;}
/* -------------------------------Custom Css End-------------------------------*/

/*====================================Contact Us Page===============================*/
.gradient-brand-color {
  background-image: -webkit-linear-gradient(0deg, #376be6 0%, #6470ef 100%)!important;
  background-image: -ms-linear-gradient(0deg, #376be6 0%, #6470ef 100%) !important;
  background-color:#376be6;
  color: #fff;
}
.contact-info__wrapper {
  overflow: hidden;
  border-radius: .625rem .625rem 0 0
}

@media (min-width: 1024px) {
  .contact-info__wrapper {
      border-radius: 0 .625rem .625rem 0;
      padding: 5rem !important
  }
}
.contact-info__list span.position-absolute {
  left: 0
}
.z-index-101 {
  z-index: 101;
}
.list-style--none {
  list-style: none;
}
.contact__wrapper {
  background-color: #fff;
  border-radius: 0 0 .625rem .625rem
}

@media (min-width: 1024px) {
  .contact__wrapper {
      border-radius: .625rem 0 .625rem .625rem
  }
}
@media (min-width: 1024px) {
  .contact-form__wrapper {
      padding: 3rem !important
  }
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(132,138,163,0.1) !important;
}
@media (min-width:320px)  {.contact-info__wrapper{padding: 2rem !important}}

/* slick carousal style */

/* the slides */
.slick-slider {
  overflow: hidden;
}
/* the parent */
.slick-list {
  margin: 0 -9px;
}
/* item */  
/* .item{
  padding: 0 9px;
} */

.slick-track {
  display: flex;
  gap: 0.8rem;
}

/* slick carousal style */

/*---------------- 23-01-2024 ------------------*/
@media screen and (max-width: 768px) {

  .nav-link {
    padding: 1rem 1.2rem !important;
    color: #fff !important;
    border-bottom: 1px solid #ddd !important;
  }
  .blackStrap{ display: none;}
}

/*---------------25-01-2024---------------*/

@media screen and (max-width: 768px) {

  .nav-link {
    padding: 1rem 1.2rem !important;
    color: #fff !important;
    border-bottom: 1px solid #ddd !important;
  }
.blackStrap{ display: none;}
.img-fluid-logo{ width: 55%;}
.navbar-brand{ font-size: 0px;}
.borderRgt{ border: none;}
.dropdown-item{ margin-top: 10px;}
.navbar-dark{ opacity: 1;}
.section-parallax{display: none;}
.slick-slider .card{ box-shadow: none;}
.usefull-link{ margin-top: 20px;}
.call-float{ margin-top: 10px;}
.inner-page-banner{ margin-top: 60px;}
.mx-5{margin-left: 0px!important;}
.phoneno-ontop{
    color: #fff;
    font-weight: bold;
    display: block;
    text-align: left;
    font-size: 12px;
    width: 60%;
    position: fixed;
    left: 20%;
    top:3%;
  }
  .site-heading h2{ font-size: 22px;}
  .navbar-nav .dropdown-menu{ height: 400px; overflow: auto;}
}



/*==================Css For responsive table (No more table)===================*/
#no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr
  { padding: 10px;}
@media only screen and (max-width: 800px) {
  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #no-more-tables tr {
    border: 1px solid #ccc;
  }
  #no-more-tables td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #ccc;
    position: relative;
    /* padding: 3% 0 3% 50%; */
    white-space: normal;
    text-align: left; 
  }
  #no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
  /*
	Label the data
	*/
  #no-more-tables td:before {
    content: attr(data-title);
  }
}

/*===================Whats app float button=====================*/

/* .whats-float {
  position: fixed;
  transform:translate(108px,0px);
  top:50%;
  right:0;
  width:135px;
  overflow: hidden;
  background-color: #25D366;
  color: #FFF;
  border-radius: 2px 0 0 2px;
  z-index: 99999;
  transition: all 0.5s ease-in-out;
  vertical-align: middle
}
.whats-float a span {
  color: #fff;
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 10px;
  position: absolute;
  line-height: 10px;
  font-weight: bold;
}

.whats-float i {
  font-size: 20px;
  color: white;
  line-height: 18px;
  padding: 6px;
  transform:rotate(0deg);
  transition: all 0.5s ease-in-out;
  text-align:center;

}

.whats-float:hover {
  color: #FFFFFF;
  transform:translate(0px,0px);
}

.whats-float:hover i  {
  transform:rotate(360deg);
} */

/*================Call Float Button ==================*/


.call-float {
  position: fixed;
  transform:translate(108px,0px);
  top:55%;
  right:0;
  width:135px;
  overflow: hidden;
  background-color: #0c5d99;
  color: #FFF;
  border-radius: 2px 0 0 2px;
  z-index: 99999;
  transition: all 0.5s ease-in-out;
  vertical-align: middle
}
.call-float a span {
  color: #fff;
  font-size: 16px;
  padding: 5px 10px 5px 5px;
  position: absolute;
  line-height: 16px;
  font-weight: bolder;
}

.call-float i {
  font-size: 14px;
  color: white;
  line-height: 10px;
  padding: 10px 2px 10px 5px;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  text-align: center;

}

.call-float:hover {
  color: #FFFFFF;
  transform:translate(0px,0px);
}

.call-float:hover i  {
  transform:rotate(360deg);
}

/*==============================CSS 27-01-2024=======================*/
@media only screen and (min-width: 1200px) {

  .phoneno-ontop{ display: none;}

}


@media only screen and (max-width: 480px) {
  .mobile-image {
    background-size: cover;
    background-position: center;
    height: 500px; /* Adjust the height as needed */
    padding-top: 10px;
    display: block;
  }
  
  .mobile-image2 {
    display: none;
  }
 
}

/* For desktop view */
@media only screen and (min-width: 480px) {
  .mobile-image2 {
    background-size: cover;
    background-position: center;
    height: 400px; /* Adjust the height as needed */
    display: block;
  }
  .mobile-image {
    display: none;
  }

}

/* For screens with width 481px and larger (desktop view) */
@media only screen and (min-width: 480px) {
  .content-mobile {
    display: none;
  }
  .content-mobile2 {
    display: none;
  }
  .content-desktop {
    display: block; /* or any other appropriate display value */
  }
}

/* For screens with width up to 480px (mobile view) */
@media only screen and (max-width: 480px) {
  .content-desktop {
    display: none;
  }
  
  .content-mobile {
    display: block;
    padding-top: 65px;
     /* or any other appropriate display value */
  }
  .content-mobile2 {
    display: block;
     /* or any other appropriate display value */
  }
}

.whats-float {
  position: fixed;
  transform: translate(108px, 0px);
  top: 50%;
  right: 0;
  width: 135px;
  overflow: hidden;
  background-color: #25D366;
  color: #FFF;
  border-radius: 2px 0 0 2px;
  z-index: 99999;
  transition: all 0.5s ease-in-out;
  vertical-align: middle;
}

.whats-float a span {
  color: #fff;
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 10px;
  position: absolute;
  line-height: 10px;
  font-weight: bold;
}

.whats-float i {
  font-size: 20px;
  color: white;
  line-height: 18px;
  padding: 6px;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  text-align: center;
}

.whats-float:hover {
  color: #FFFFFF;
  transform: translate(0px, 0px);
}

.whats-float:hover i {
  transform: rotate(360deg);
}

/* Additional styles for the WhatsApp button with pulse effect */
.btn-whatsapp-pulse {
  background: #25d366;
  color: white;
  position: fixed;
  bottom: 20px;
  left: 30px; /* Adjusted left property */
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 30px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index: 99999;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .btn-whatsapp-pulse {
    bottom: 35px;
    left: 15px; /* Adjusted left property for mobile devices */
    font-size: 35px; /* Adjusted font size for mobile devices */
    padding: 28px; /* Adjusted padding for mobile devices */
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  80% {
    box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
  }
}

.btn-whatsapp-pulse-border {
  bottom: 100px;
  right: 20px;
  animation-play-state: paused;
}

.btn-whatsapp-pulse-border::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  padding: 25px;
  border: 5px solid #25d366;
  opacity: 0.75;
  animation-name: pulse-border;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-border {
  0% {
    padding: 25px;
    opacity: 3.5; /* Increase opacity for better visibility */
  }
  75% {
    padding: 50px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* Additional styles for the call button with pulse effect */
.btn-call-pulse {
  background: #0c5d99;
  color: white;
  position: fixed;
  bottom: 20px;
  right: 30px; /* Adjusted right property */
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 28px;
  text-decoration: none;
  border-radius: 50%;
  animation-name: pulse-call;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index: 99999;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .btn-call-pulse {
    bottom: 30px;
    right: 15px; /* Adjusted right property for mobile devices */
    font-size: 30px; /* Adjusted font size for mobile devices */
    padding: 25x; /* Adjusted padding for mobile devices */
  }
}

@keyframes pulse-call {
  0% {
    box-shadow: 0 0 0 0 rgba(12, 93, 153, 0.5);
  }
  80% {
    box-shadow: 0 0 0 14px rgba(12, 93, 153, 0);
  }
}

.btn-call-pulse-border {
  bottom: 100px;
  right: 20px;
  animation-play-state: paused;
}

.btn-call-pulse-border::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  padding: 25px;
  border: 5px solid #0c5d99;
  opacity: 0.75;
  animation-name: pulse-border-call;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse-border-call {
  0% {
    padding: 25px;
    opacity: 3.5; /* Increase opacity for better visibility */
  }
  75% {
    padding: 50px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.custom-container {
  padding-left: 7px; /* Adjust as needed */
  padding-right: 6px; /* Adjust as needed */
}



.image-table {
  width: 100%;
  text-align: center; /* Center align the content inside the table */
  border-collapse: collapse; /* Collapse table borders */
  border: 1px solid #ddd; /* Table border */

}

.image-cell {
  padding: 10px; /* Remove default padding */
}

.image-cell img {
  max-width: 100%;
  height: auto; /* Maintain the aspect ratio */
  margin: 0 5px; /* Add space between images */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .image-cell {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align images */
  }

  .image-cell img {
    margin: 5px 0; /* Vertical space between images */
  }
}

.dropdown-item2 {
  display: none;
}

.custom-toast {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}


.book-now-btn {
  font-size: 14px; /* Smaller font size to fit on one line */
  padding: 8px 16px; /* Adjust padding to keep the button compact */
  white-space: nowrap; /* Prevent text from breaking into a new line */
  display: inline-block; /* Ensure it stays inline */
  border-radius: 4px;
  text-align: center;
  color: white; /* Text color */
  background-color: #005595; /* Dark blue background */

}

.image-padding {
  padding-left: 40px; /* Adjust value as needed */
}


.video-container {
  position: relative;
  width: 100%;  /* Full width for responsiveness */
  max-width: 786px;  /* Optional: Restrict max size for large screens */
  margin: 0 auto;  /* Center-align the container */
  padding-bottom: 56.25%;  /* Maintain 16:9 aspect ratio (56.25% = 9/16) */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;  /* Ensure iframe takes full width */
  height: 100%;  /* Ensure iframe takes full height */
}

.yellow{
  background-color: rgba(255, 218, 0, 0.5);
}

.yellow th {
  height: 50px;
  vertical-align: middle;
}


/* General styling for the image */
.responsive-image {
  width: 100%;
  max-width: 290px;
  height: auto;
}

/* Additional styling for mobile devices */
@media (max-width: 768px) {
  .responsive-image {
    max-width: 100%;
    width: auto;
  }
}

/* -------------------------pop up image prev  */
.image-popup-prev {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  width: 40px; /* Set a fixed width */
  height: 40px; /* Set a fixed height to ensure circular shape */
  border-radius: 50%; /* Makes the background a perfect circle */
  cursor: pointer;
  z-index: 1060; /* Ensure it's above the image */
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the content */
  font-size: 16px; /* Adjust icon size */
}

/* -------------------------pop up image next  */

.image-popup-next {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height to ensure circular shape */
  border-radius: 50%; /* Makes the button a perfect circle */
  cursor: pointer;
  z-index: 1060; /* Ensures the button is above the image */
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the icon inside the button */
  font-size: 16px; /* Adjust the icon size */
}



@media (min-width: 768px) {
  .modal-xl {
    width: 100%;
   max-width:1200px;
  }
}


/* Default styles (for mobile and smaller devices) */
.crousel-slide-h3, 
.carousel-text-p {
  background-color: rgba(0, 0, 0, 0.7); /* Blackish background with opacity */
  color: white; /* Text color */
  padding: 8px; /* Slightly smaller padding for mobile */
  border-radius: 4px; /* Slightly smaller corners for mobile */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Lighter shadow for mobile */
  display: inline-block; /* Ensures the background wraps tightly around text */
  font-size: 14px; /* Smaller font size for mobile */
}

/* Styles for tablets and larger devices (min-width: 768px) */
@media (min-width: 768px) {
  .crousel-slide-h3, 
  .carousel-text-p {
    padding: 10px; /* Larger padding for better spacing */
    border-radius: 5px; /* Slightly larger corners */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* More pronounced shadow */
    font-size: 24px; /* Larger font size for bigger screens */
  }
}

.tomato1 {
  background-color: tomato; /* Background color */
  color: white; /* Text color for contrast */
  padding: 10px; /* Add padding for spacing */
  border-radius: 5px; /* Optional: rounded corners */
  display: inline-block; /* Ensure background wraps tightly around text */
}


/* Ensure modal is responsive on smaller screens */
@media (max-width: 768px) {
  .modal-dialog {
    max-width: 100%; /* Make the modal full width on mobile */
    margin: 0; /* Remove any margin on mobile */
  }

  .modal-content {
    width: 100%; /* Ensure the modal content also takes full width */
  }

  .carousel img {
    max-width: 100%; /* Ensure images are responsive */
    height: auto; /* Allow height to scale proportionally */
  }
}

.img-center {
  width: 100%;
  text-align: center;
}


.about-adorable{
  padding-top: 60px;
  padding-bottom: 60px;
}

.about-adorable1{
  padding-right: 70px;
  padding-left: 70px;
}




.album .responsive-container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  /* padding-top: 10px; */
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  justify-content: flex-start;
}

.album .responsive-container-block.bg {
  max-width: 1320px;
  margin: 0 0 0 0;
  justify-content: space-between;
}

.album .img {
  width: 100%;
  margin: 0 0 20px 0;
}

.album #i9rb {
  color: black;
}

.album #ir6i {
  color: black;
}

.album #ikz3b {
  color: black;
}

.album .responsive-container-block.img-cont {
  flex-direction: column;
  max-width: 33.3%;
  min-height: auto;
  margin: 0 0 0 0;
  height: 100%;
}

.album #ipix {
  color: black;
}

.album #ipzoh {
  color: black;
}

.album #ig5q8 {
  color: black;
}

.album #imtzl {
  color: black;
}

.album #i53es {
  color: black;
}

.album .img.img-big {
  height: 50%;
  margin: 0 0 16px 0;
}

@media (max-width: 1024px) {
  .album .img {
    margin: 0 0 18px 0;
  }
}

@media (max-width: 768px) {
  .album .img {
    max-width: 32.5%;
    margin: 0 0 0 0;
  }

  .album .responsive-container-block.bg {
    flex-direction: column;
  }

  .album .responsive-container-block.img-cont {
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .album .img.img-big {
    max-width: 49%;
    margin: 0 0 0 0;
  }
}

@media (max-width: 500px) {
  .album .img {
    max-width: 94%;
    margin: 0 0 25px 0;
  }

  .album .responsive-container-block.img-cont {
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 10px 10px;
  }

  .album .img.img-big {
    max-width: 94%;
    margin: 0 0 25px 0;
  }

  .album .img.img-last {
    margin: 0 0 5px 0;
  }
}


